import React from "react";
import Page from "../../components/page";


export const Error404: React.FC<any> = () => {
    return (
        <Page>
            error page the first one
            <div>
                error page
            </div>
            <div>
                error page
            </div>
            <div>
                error page
            </div>
            <div>
                error page
            </div>
            <div>
                error page
            </div>
            <div>
                error page
            </div>
        </Page>
    )
}