import {configureStore, createSlice} from "@reduxjs/toolkit"

const postSlice = createSlice( {
    name: "post",
    initialState: {},
    reducers: {}
})
export const store = configureStore({
    reducer: {
        posts: postSlice.reducer,
    }
})


