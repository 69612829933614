import React from "react";

export interface IPageProps {
    children?: React.ReactNode
}
const Page: React.FC<IPageProps> = ({children}) => {
    return (
        <div style={{display: "flex",flexDirection: "column", minHeight: "100vh", minWidth: "100vw"}}>
            <div style={{flexGrow: 1, paddingBottom: 20, paddingTop: 40}}>
                {children}
            </div>

        </div>
    )
}

export default Page