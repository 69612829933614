import { createBrowserRouter} from "react-router-dom";
import {Error404} from "../../pages/error-404/Error404";

export const router = createBrowserRouter([
    {
        path: "/",
        lazy: () => import("../layout"),
        children: [
            {
                index: true,
                lazy: () => import("pages/home")
            },
            {
                path: "/rules",
                lazy: () => import("pages/rules")
            },
            {
                path: "/books",
                lazy: () => import("pages/books")
            },


            {
              path: "*",
                element: <Error404/>,
            }
        ]
    }
])