import React, {Suspense} from 'react';
import 'semantic-ui-css/semantic.min.css'
import {Provider} from "react-redux";
import {store} from "./store";
import {Loader, Segment} from "semantic-ui-react";
import {RouterProvider} from "react-router-dom";
import {router} from "./router";

export const App: React.FC = () => {
  return(
      <Provider store={store} >
        <Suspense fallback={<Segment><Loader active={true}/></Segment> }>
            <RouterProvider router={router}/>
        </Suspense>
      </Provider>
  )
}
export const element = <App/>

export default App